*, *:before, *:after {
  box-sizing: border-box;
}

*:focus {
	outline-offset: 6px;
}

body {
	margin:0;
	padding:0;
	height: 100%;
	font-family: $main-font;
  color: $text-dark;
  min-width: $min-width;
}

a,button {
	text-decoration:none;
	color: #999;
  transition: color 0.3s ease-out, background-color 0.3s ease-out, border-color 0.3s ease-out
}

p { margin-top:0; margin-bottom:15px; }
ol,
ul {
  padding-left: 30px;
  li {

  }
}

hr {
  border:none;
  border-bottom: 1px solid $border;
  margin: 20px 0;
}


.clear { clear:both; }


.flex {
	display: flex;
	margin: 0 -15px;
}
.flex--wrap {
	flex-wrap: wrap;
}
.flex--align-v {
	align-items: center;
}
.flex .col { flex-grow: 1; }
.flex .col-2 { width:16.6666%; }
.flex .col-3 { width:25%; }
.flex .col-4 { width:33.3333%; }
.flex .col-5 { width:41.6666%; }
.flex .col-6 { width:50%; }
.flex .col-7 { width:58.3333%; }
.flex .col-8 { width:66.6666%; }
.flex .col-9 { width:75%; }
.flex .col-12 { width:100%; }
.flex .col-20 { width:20%; }
.flex .col-40 { width:40%; }
.flex .col, .flex .col-2, .flex .col-3, .flex .col-4, .flex .col-5, .flex .col-6, .flex .col-7, .flex .col-8, .flex .col-9, .flex .col-12, .col-20, .col-40 {
	padding: 0 15px;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}

.hidden {
	display: none;
}
.screen-reader-text,
.visually-hidden {
	position: absolute !important;
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
	height: 1px;
	width: 1px;
	word-wrap: normal;
}
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
	position: static !important;
	clip: auto;
	overflow: visible;
	height: auto;
	width: auto;
}
.invisible {
	visibility: hidden;
}

.notransition {
	-o-transition: none !important;
	-ms-transition: none !important;
	-moz-transition: none !important;
	-webkit-transition: none !important;
	transition: none !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

#root {
	width: 100%;
	height:100vh;
}
