button {
	-moz-appearance: none;
	-webkit-appearance: none;
	border: none;
	background: transparent;
	padding:0;
	//text-decoration: underline;
	color: $text-dark;
	cursor: pointer;
}

.btn-wrap {
	margin:0 -3px;
	&--centered {
		text-align: center;
	}
	&--right {
		text-align: right;
	}
	.btn {
		vertical-align: middle;
		margin:0 3px;
	}
}


.btn {
	display: inline-block;
	padding: 8px 10px;
	color: #fff;
	background: $dark-grey;
	border: 1px solid $dark-grey;
	border-radius: 19px;
	cursor: pointer;
	transition: color 0.3s ease-out, border-color 0.3s ease-out, background-color 0.3s ease-out;
	font-weight: 300;
	@include font-size(16);
	line-height: 1;
	text-align: center;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	position: relative;
	height: 38px;
	min-width: 38px;
	text-decoration: none;
	&:active, &:hover, &:focus {
		color: #fff;
		background: darken($dark-grey, 20%);
		border: 1px solid $dark-grey;
	}
	&:disabled {

	}
	&:focus {
		outline-style: none;
		&:after {
			content:"";
			position: absolute;
			left:-6px;
			right:-6px;
			top:-6px;
			bottom:-6px;
			-webkit-box-shadow: 0 0 0.0625rem 0.125rem $text-dark;
			box-shadow: 0 0 0.0625rem 0.125rem $text-dark;
			border-radius: 0.25rem;
			z-index: 90
		}
	}
	&.btn-action {
		padding: 8px 12px;
		border-radius: 10px;
		@include font-size(14);
		height: 30px;
		min-width: 30px;
		color: #fff;
		background: $blue;
		border: 1px solid $blue;
		&:active, &:hover, &:focus {
			color: #fff;
			background: darken($blue, 20%);
			border: 1px solid $blue;
		}
	}
	&.btn-inline {
		padding: 8px 4px;
		@include font-size(14);
		height: 30px;
		min-width: 30px;
		background: transparent;
		border:none;
		color: $text-dark;
		&:active, &:hover, &:focus {
			color: $blue;
		}
	}
	&.is-spinning {
		position: relative;
		padding-left:40px;
		&:before {
			content:"\e839";
			font-family: fontello;
			position: absolute;
			top:50%;
			font-size: 14px;
			line-height: 1;
			margin-top: -7px;
			left:10px;
			-webkit-animation: spin 2s infinite linear;
		}
	}
	@include respond-vsmall-down {
		width: 100%;
	}
	&:disabled, &.disabled {
		opacity: 0.5;
	}
}

.btn-blue {
	color: #fff;
	background: $blue;
	border: 1px solid $blue;
	&:active, &:hover, &:focus {
		color: #fff;
		background: darken($blue, 20%);
		border: 1px solid $blue;
	}
}

.btn-pink {
	color: #fff;
	background: $pink;
	border: 1px solid $pink;
	&:active, &:hover, &:focus {
		color: #fff;
		background: darken($pink, 20%);
		border: 1px solid $pink;
	}
}

.btn-green {
	color: #fff;
	background: $green;
	border: 1px solid $green;
	&:active, &:hover, &:focus {
		color: #fff;
		background: darken($green, 20%);
		border: 1px solid $green;
	}
}

.btn-toggle {
	opacity: 0.3;
	&--on {
		opacity: 1;
	}
}


.btn-move {
	cursor: grab;
}
.is-dragging {
	cursor: grabbing !important;
}


.tabbed-pages {
	&__inner {

	}
	.tabs {
		width: 100%;
		padding: 0;
		text-align: left;
		overflow: hidden;
		position: relative;
		&:after {
			content:'';
			width: 100%;
			height:1px;
			background:$border-light;
			position:absolute;
			bottom:0;
			lefT:0;
			right:0;
		}
		.tab {
			display: inline-block;
			-moz-appearance: none;
			-webkit-appearance: none;
			border: none;
			background: transparent;
			padding:10px 20px 13px 20px;
			color: $text-dark;
			cursor: pointer;
			position: relative;
			@include font-size(11);
			span {
				position: relative;
				z-index: 2;
			}
			&:after {
				content:'';
				position:absolute;
				top:0;
				bottom:-2px;
				left:0;
				right:0;
				background-color: #fff;
				transition: opacity 0.1s ease-out, transform 0.1s ease-out;
				z-index: 1;
				border-top: 1px solid $border-light;
				border-left: 1px solid $border-light;
				border-right: 1px solid $border-light;
				opacity: 0;
				transform: translate(0,100%);
			}
			&.active:after {
				opacity:1;
				transform: translate(0,0);
			}
		}
	}
	.pages {
		.page {
			display: none;
			padding: 20px 0;
			&.active {
				display: block;
			}
		}
	}
}
