.table {
	display: table;
}

.table-cell {
	display: table-cell;
}

.valign-m {
	vertical-align: middle;
}

.form-group {
  margin-top: 30px;
	&:first-of-type {
		margin-top: 0;
	}
}

.form-row {
	margin-bottom: 20px;
	input[type=text],
	input[type=number],
	input[type=tel],
	input[type=password],
	input[type=email],
	input[type=date],
	.form-html-view,
	.input-readonly,
	textarea, select {
		width: 100%;
		margin: 0;
		display: inline-block;
		border: none;
		border-bottom: 1px solid $border-light;
		background: #F7F7F7;
		box-shadow: none; //inset 0 1px 3px #ddd;
		border-radius: 0;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		padding: 10px;
		@include font-size(15);
    line-height: 1;
    height:36px;
		-webkit-appearance: none;
		appearance: none;
		font-family: 'Source Sans Pro', sans-serif;
		&.error {
			border-left: 5px solid #ff185a !important;
		}
		&:focus {
			outline: none;
			border-color: $blue;
		}
	}
	textarea {
		resize: vertical;
		height: auto;
	}
	input[type=date] {
		// font-family: inherit;
		text-transform: uppercase;
	}
	.form-html-view {
		background-color: #fff;
		max-height: 300px;
		overflow: scroll;
		box-shadow: none;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.09);
		min-height: 150px;
		blockquote {
			padding: 5px 30px;
			border-left: 4px #00142a solid;
		}
	}
	.editor-wrap {
		&.error {
			border-left: 5px solid #ff185a !important;
		}
	}
	input[type=checkbox] {
		vertical-align: middle;
	}
	label {
		width: 100%;
		display: block;
		margin-bottom: 5px;
		//@include font-size(14);

		@include font-size(11);
		font-weight: bold;
		text-transform: uppercase;
		color: $text-grey;
		&.label-inline {
			text-transform: none;
			span {
				vertical-align: middle;
			}
		}
	}
	.btn-wrap {
		margin-top: 50px;
	}
	.date-to-from {
		width:100%;
		display: flex;
		input {
			width:50%;
		}
	}
	select {
		background:#F7F7F7 url(../images/angle-down.svg) no-repeat;
		background-size: 10px 20px;
		background-position: calc(100% - 10px) 50%;
		padding-right: 40px;
	}
}



.input-search {
	height:34px;
	@include font-size(12);
	line-height: 1;
	padding:11px 20px 11px 30px;
	border-radius: 5px;
	border:none;
	background: #fff url(../images/icon-search.svg) no-repeat;
	background-size: 11px 11px;
	background-position: 10px 50% ;
	width: 100%;
	max-width: 520px;
}

::-webkit-input-placeholder {
  color:rgba(58, 73, 98, 0.5);
}
::-moz-placeholder {
  color:rgba(58, 73, 98, 0.5);
}
::-ms-placeholder {
  color:rgba(58, 73, 98, 0.5);
}
::placeholder {
  color:rgba(58, 73, 98, 0.5);
}

.required {
	color: $required;
	&:after {
		content: '*';
		display: inline-block;
		margin-left: 10px;
		color: $required;
	}
}

.form-row .midwidth {
	max-width: 160px;
}

.css-1rhbuit-multiValue {
  background-color:$complete !important;
	color:#fff !important;

	.css-12jo7m5 {
		color:#fff !important;
    padding: 6px 5px !important;
		@include font-size(14);
	}
}


.css-textarea {
  display: flex;
	.line-numbers {
		padding:4px 0;
	}
	.line-num {
    counter-increment: line-number;
    padding-right: 1em;
    text-align: right;
    width: 2em;
		font-size: 15px;
		line-height: 1.6;
		color: lighten($text-grey, 20%);
  }
  textarea {
		padding-top:4px;
		padding-bottom:4px;
    flex: 1;
    padding-left: 1em;
    resize: none;
		font-size: 15px;
		line-height: 1.6;
  }
}



.color-picker {
	display: block;
	position: relative;
	&__swatch {
		padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
		> div {
			width:40px;
			height:26px;
		}
	}
	&__popover {
		position: absolute;
		z-index: 2;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
		span > div {
    	box-shadow: 0 0 0 1px rgba(0,0,0,.1);
		}
	}
	&__clicktrap {
		position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	}
}

// .drop-zone {
// 	width:100%;
// 	@include font-size(14);
// 	padding:0 0 20px 0;
// 	&__inner {
// 		width:100%;
// 		text-align: center;
// 		border: 1px dashed rgba(#3A4962, 0.25);
// 		background: $light-grey;
// 		border-radius: 8px;
// 		color: rgba(#3A4962, 0.3);
// 		padding: 50px 0;
// 		pointer-events: none;
// 	}
// 	.icon {
// 		@include font-size(30);
// 		margin-bottom: 10px;
// 	}
// 	.icon-upload-cloud:before {
// 		width: 100px;
// 	}
// 	.headline {
// 		@include font-size(20);
// 	}
// 	.browse {
// 		margin-top: 4px;
// 		color: $dark-blue;
// 		text-decoration: underline;
// 		transition: color 0.3s ease-out;
// 	}
// 	.files {
// 		margin-top: 20px
// 	}
// 	.filename {
// 		margin-top: 2px;
// 		color: $text-dark;
// 	}
// 	.errors {
// 		margin-top: 20px
// 	}
// 	&.is-dragover {
// 		.drop-zone__inner {
// 	    border: 2px dashed $complete;
// 			background: $grey;
// 	  }
// 	}
// 	&:hover {
// 		cursor: pointer;
// 		.browse { color: $blue; }
// 	}
// }
