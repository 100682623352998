.page-header {
	height:67px;
	width: 100%;
	background: $grey;
	display: flex;
	align-items: center;
	padding: 0 15px 0 10px;
	position:absolute;
	top:0;
	left:0;
	right:0;
	z-index: 999;
	min-width:$min-width;
	&__inner {
		position: relative;
		display: flex;
		    align-items: center;
		width: 100%;
	}
	.logo {
		width:63px;
		height:45px;
		margin:0 15px 0 4px;
	}
	.link-back {

	}
	.doc-meta {
		margin-left: 20px;
		flex-grow: 1;
		display: flex;
		align-items: center;
		color:$text-mid;
		@include font-size(22);
		line-height: 1;
		&__doctype {
			font-weight: bold;
			padding-right: 20px;
			margin-right: 20px;
			position: relative;
			&:after {
				content:'';
				position: absolute;
				top:50%;
				left:100%;
				width: 1px;
				background-color: $text-mid;
				height:22px;
				margin-top: -11px;
			}
		}
		&__title {
		}
	}
	.actions {

	}
}
