@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?40099771');
  src: url('../font/fontello.eot?40099771#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?40099771') format('woff2'),
       url('../font/fontello.woff?40099771') format('woff'),
       url('../font/fontello.ttf?40099771') format('truetype'),
       url('../font/fontello.svg?40099771#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?40099771#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-picture:before { content: '\e800'; } /* '' */
.icon-menu:before { content: '\e801'; } /* '' */
.icon-trash-empty:before { content: '\e802'; } /* '' */
.icon-align-left:before { content: '\e803'; } /* '' */
.icon-align-center:before { content: '\e804'; } /* '' */
.icon-align-right:before { content: '\e805'; } /* '' */
.icon-align-justify:before { content: '\e806'; } /* '' */
.icon-pencil:before { content: '\e807'; } /* '' */
.icon-clock:before { content: '\e808'; } /* '' */
.icon-export:before { content: '\e809'; } /* '' */
.icon-print:before { content: '\e80a'; } /* '' */
.icon-edit:before { content: '\e80b'; } /* '' */
.icon-eye:before { content: '\e80c'; } /* '' */
.icon-eye-off:before { content: '\e80d'; } /* '' */
.icon-lock:before { content: '\e80e'; } /* '' */
.icon-pin:before { content: '\e80f'; } /* '' */
.icon-help-circled:before { content: '\e810'; } /* '' */
.icon-info-circled:before { content: '\e811'; } /* '' */
.icon-ok:before { content: '\e812'; } /* '' */
.icon-ok-circled:before { content: '\e813'; } /* '' */
.icon-star-empty:before { content: '\e814'; } /* '' */
.icon-star:before { content: '\e815'; } /* '' */
.icon-heart-empty:before { content: '\e816'; } /* '' */
.icon-heart:before { content: '\e817'; } /* '' */
.icon-search:before { content: '\e818'; } /* '' */
.icon-video:before { content: '\e819'; } /* '' */
.icon-download:before { content: '\e81a'; } /* '' */
.icon-upload:before { content: '\e81b'; } /* '' */
.icon-attention:before { content: '\e81c'; } /* '' */
.icon-cog:before { content: '\e81d'; } /* '' */
.icon-cog-alt:before { content: '\e81e'; } /* '' */
.icon-resize-horizontal:before { content: '\e81f'; } /* '' */
.icon-resize-vertical:before { content: '\e820'; } /* '' */
.icon-resize-small:before { content: '\e821'; } /* '' */
.icon-resize-full:before { content: '\e822'; } /* '' */
.icon-zoom-in:before { content: '\e823'; } /* '' */
.icon-zoom-out:before { content: '\e824'; } /* '' */
.icon-text:before { content: '\e825'; } /* '' */
.icon-floppy:before { content: '\e826'; } /* '' */
.icon-camera-outline:before { content: '\e827'; } /* '' */
.icon-camera:before { content: '\e828'; } /* '' */
.icon-menu-outline:before { content: '\e829'; } /* '' */
.icon-font:before { content: '\e82a'; } /* '' */
.icon-layer-group:before { content: '\e82b'; } /* '' */
.icon-image:before { content: '\e82c'; } /* '' */
.icon-draw-square:before { content: '\e82d'; } /* '' */
.icon-ellipsis-vertical:before { content: '\e82e'; } /* '' */
.icon-bold:before { content: '\e82f'; } /* '' */
.icon-italic:before { content: '\e830'; } /* '' */
.icon-h2:before { content: '\e831'; } /* '' */
.icon-h1:before { content: '\e832'; } /* '' */
.icon-h3:before { content: '\e833'; } /* '' */
.icon-h2-1:before { content: '\e834'; } /* '' */
.icon-h4:before { content: '\e835'; } /* '' */
.icon-h5:before { content: '\e836'; } /* '' */
.icon-h6:before { content: '\e837'; } /* '' */
.icon-p:before { content: '\e838'; } /* '' */
.icon-paragraph:before { content: '\e839'; } /* '' */
.icon-quote-left:before { content: '\e83a'; } /* '' */
.icon-quote-right:before { content: '\e83b'; } /* '' */
.icon-menu-1:before { content: '\f008'; } /* '' */
.icon-move:before { content: '\f047'; } /* '' */
.icon-resize-full-alt:before { content: '\f0b2'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-list-bullet:before { content: '\f0ca'; } /* '' */
.icon-list-numbered:before { content: '\f0cb'; } /* '' */
.icon-underline:before { content: '\f0cd'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-attention-alt:before { content: '\f12a'; } /* '' */
.icon-rocket:before { content: '\f135'; } /* '' */
.icon-lock-open-alt:before { content: '\f13e'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-mouse-pointer:before { content: '\f245'; } /* '' */
.icon-clone:before { content: '\f24d'; } /* '' */
.icon-low-vision:before { content: '\f2a8'; } /* '' */
