
// Breakpoints
$bp-vlarge: 1400;
$bp-tablet-landscape: 1024;
$bp-large: 950;
$bp-med: 768;
$bp-small: 560;
$bp-vsmall: 320;

// Mixins
@mixin respond-vlarge-up() {
	@media (min-width: #{$bp-vlarge}px) {
		@content;
	}
}

@mixin respond-vlarge-down() {
	@media (max-width: #{($bp-vlarge - 1)}px) {
		@content;
	}
}

@mixin respond-large-up() {
	@media (min-width: #{$bp-large}px) {
		@content;
	}
}

@mixin respond-large-down() {
	@media (max-width: #{($bp-large - 1)}px) {
		@content;
	}
}

@mixin respond-large-down-landscape() {
	@media (max-width: #{($bp-large - 1)}px) and (orientation : landscape) {
		@content;
	}
}

@mixin respond-large-down-portrait() {
	@media (max-width: #{($bp-large - 1)}px) and (orientation : portrait) {
		@content;
	}
}

@mixin respond-tablet-landscape-down() {
	@media (max-width: #{($bp-tablet-landscape)}px) {
		@content;
	}
}

@mixin respond-medium-only() {
	@media (min-width: #{$bp-med}px) and (max-width: ($bp-large - 1)px) {
		@content;
	}
}

@mixin respond-medium-up() {
	@media (min-width: #{$bp-med}px) {
		@content;
	}
}

@mixin respond-medium-down() {
	@media (max-width: #{($bp-med - 1)}px) {
		@content;
	}
}

@mixin respond-medium-down-portrait() {
	@media (max-width: #{($bp-med - 1)}px) and (orientation : portrait) {
		@content;
	}
}

@mixin respond-small-up() {
	@media (min-width: #{$bp-small}px) {
		@content;
	}
}

@mixin respond-small-down() {
	@media (max-width: #{$bp-small}px) {
		@content;
	}
}

@mixin respond-vsmall-down() {
	@media (max-width: #{$bp-vsmall}px) {
		@content;
	}
}

@mixin retina() {
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		@content;
	}
}

@mixin font-size($value) {
	font-size: #{$value}px;
  font-size: #{($value / 16)}rem;
}

@mixin pos-fixed() {
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
}
