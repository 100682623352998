.layers {
	border-top: 1px solid $border-light;
	&__items {

	}
	&__tools {
		border-top: 1px solid $border-light;
		padding:5px 10px;
	}

}

.layer {
	background: $light-grey;
	padding:10px;
	color: $text-dark;
	border-left: 4px solid $light-grey;
	border-bottom: 1px solid $border-light;
	transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
	&:hover,&:active,&:focus {
		border-left: 4px solid $grey;
		background: darken($light-grey, 10%);
		cursor: pointer;
	}
	// &.is-selected {
	// 	border-left: 4px solid $blue;
	// 	background: rgba($blue, 0.3);
	// 	cursor: pointer;
	// }
	&:last-child {
		border-bottom: none;
	}
	&__header {
		display: flex;
		align-items: center;
	}
	&__info {
		flex-grow: 1;
		text-transform: capitalize;
	}
	&__title {
		@include font-size(16);
	}
	&__copy {
		margin-top: 4px;
		@include font-size(11);
		color:rgba($text-dark, 0.5)
	}

	.btn-move-up,
	.btn-move-down {
		min-width: auto;
		padding:0;
	}

	&--indent {
		padding-left: 20px;
		&.group-selected {
			border-left: 4px solid $blue !important;
		}
	}

	&--text,
	&--image,
	&--list,
	&--repeater,
	&--group,
	&--shape {
		.layer__info .layer__title:before {
			font-family: fontello;
			display: inline-block;
			margin-right: 10px;
		}
	}

	&--text {
		.layer__info .layer__title:before {
			content: '\E82A'
		}
	}
	&--image {
		.layer__info .layer__title:before {
			content: '\E82C'
		}
	}
	&--group {
		.layer__info .layer__title:before {
			content: '\E82B'
		}
	}
	&--shape {
		.layer__info .layer__title:before {
			content: '\E82D'
		}
	}
	&--list {
		.layer__info .layer__title:before {
			content: '\F0CA'
		}
	}
	&--repeater {
		.layer__info .layer__title:before {
			content: '\E801'
		}
	}
}

.mode--select .layers .layer {
	&.is-hovering {
		border-left: 4px solid $grey;
		background: darken($light-grey, 10%);
		cursor: pointer;
	}
	&.is-selected {
		border-left: 4px solid $blue;
		background: rgba($blue, 0.3);
		cursor: pointer;
	}
}
