.settings {
	background: #fff;
	border-left:1px solid $border;
	position:absolute;
	top:67px;
	right:0;
	bottom:0;
	padding-bottom: 100px;
	width: 406px;
	z-index: 9999;
	overflow: auto;
	.s-panel {
		border-bottom: 1px solid $border;
		&__header {
			@include font-size(14);
			font-weight: 600;
			padding:8px 30px 8px 10px;
			text-transform: uppercase;
			cursor: pointer;
			position:relative;
			border-bottom: 2px solid $border-dark;
		}
		&__handle {
			position:absolute;
			top:8px;
			right:5px;
			i:before {
				transition: transform 0.4s ease-out;
			}
		}
		&__content {
			@include font-size(12);
			color:$text-grey;
			.content {
				padding:10px 15px;
			}
		}
		&:last-child {
			border-bottom: none;
		}

		&.is-open {
			.s-panel__handle {
				i:before {
					transform: rotateX(180deg);
				}
			}
		}
	}

	.type-toggles {
		margin: 0 -5px 15px -5px;
		.btn-toggle {
			@include font-size(18);
			line-height: 1;
			padding:5px;
		}
	}

	.gallery-tiles {
		display: flex;
		flex-wrap: wrap;
		margin:10px -5px;
		.gallery-tile {
			width: 20%;
			padding:5px;
			&__inner {
				width: 100%;
				height:0;
				padding-bottom: 100%;
				border:$border 1px solid;
				background-color: $light-grey;
				background-size: cover !important;
			}
		}
	}
}
