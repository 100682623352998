
.editor-wrap {
	margin-bottom: 30px;
}
.editor {
	border: 1px solid $border-light;
	min-height: 300px;
	cursor: text;
	padding: 15px;

	h1,h2,h3,h4 {
		margin:0 0 10px 0;
		line-height:1.4;
		font-weight: 500;
		text-transform: none;
	}

	blockquote {
		border-left: 4px solid $border-light;
		margin-left: 0;
		padding-left: 20px;
	}
}
.toolbar {
  border-left: 1px solid $border-light;
  border-top: 1px solid $border-light;
	border-right: 1px solid $border-light;
  padding:  0;
  text-align: left;
	button {
		-moz-appearance: none;
		-webkit-appearance: none;
		border: none;
		background: transparent;
		padding:0 10px;
		width: auto;
		height:35px;
		text-align: center;
		border-right: 1px solid $border-light;
		cursor: pointer;
		i:before {
			width: auto;
		}
		&:hover, &:focus, &:active {
			background-color: $border-light;
			color: $text-dark
		}
		&.active {
			background-color: $border-light;
			color: $text-dark
		}
	}
}
