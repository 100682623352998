.tools {
	background: #fff;
	border:1px solid $border-light;
	position:absolute;
	top:87px;
	left:10px;
	width: 56px;
	padding: 2px;
	z-index: 9999;
	&__inner {
	}
	ul {
		margin:0;
		padding:0;
		li {
			list-style: none;
			margin:0 0 2px 0;
			padding:0;
			&:last-child {
				margin:0;
			}
			button {
				width: 50px;
				height: 50px;
				border: 1px solid $border;
				@include font-size(18);
				&:hover, &:active, &:focus {
					background: $mid-grey;
				}
				&.active {
					background: $blue;
					color: #fff;
				}
			}
		}
	}
}


.resize-tools {
	position: absolute;
	pointer-events: none;
	&:after {
		content:'';
		position: absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		border:2px solid $blue;
		z-index:0;
		pointer-events: none;
	}

	&__inner {

	}

	.lbl {
		position:absolute;
		padding:4px 6px;
		background: #fff;
		@include font-size(12);
		line-height: 1;
		border:1px solid $blue;
		border-radius:10px;
		&--width {
			top:calc(100% + 10px);
			left:50%;
			transform: translate(-50%,0);
		}
		&--height {
			left:calc(100% + 10px);
			top:50%;
			transform: translate(0,-50%);
		}
		&--xy {
			left:0;
			bottom:calc(100% + 10px);
			color:lighten($text-grey, 10%);
			span {
				color:$text-dark;
			}
		}
	}
	.rt {
		position:absolute;
		width: 10px;
		height: 10px;
		background:#fff;
		border:2px solid $blue;
		z-index: 2;
  	pointer-events: auto;
		border-radius: 4px;
		&--t {
			width: 20px;
			top: -5px;
			left:50%;
			margin-left: -10px;
			cursor: n-resize;
		}
		&--b {
			width: 20px;
			bottom: -5px;
			left:50%;
			margin-left: -10px;
			cursor: s-resize;
		}
		&--l {
			height: 20px;
			top: 50%;
			margin-top: -10px;
			left:-5px;
			cursor: w-resize;
		}
		&--r {
			height: 20px;
			top: 50%;
			margin-top: -10px;
			right:-5px;
			cursor: e-resize;
		}
		&--tl {
			top: -5px;
			left: -5px;
			cursor: nw-resize;
		}
		&--tr {
			top: -5px;
			right: -5px;
			cursor: ne-resize;
		}
		&--bl {
			bottom: -5px;
			left: -5px;
			cursor: sw-resize;
		}
		&--br {
			bottom: -5px;
			right: -5px;
			cursor: se-resize;
		}
	}
}
