.stage {
	width: 100%;
	height: 100%;
	background: $light-grey;
	position:relative;
	overflow: hidden;
	&__inner {
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		overflow: auto;
	}
}
