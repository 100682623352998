.artboard {
	position:relative;
	&__title {
		margin-bottom: 10px;
	}
	&__inner {
		background: #fff;
		box-shadow: 0 0 10px rgba(0,0,0,0.05);
		position:absolute;
		top:107px;
		left:80px;
	}

	.objects {
		position:relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: 1;
		.object {
			position:absolute;
			//background: rgba(233,233,233,0.5);
			overflow: hidden;
			&--text {
				.noselect {
					pointer-events: none;
				}
				textarea {
					width:100%;
					height:100%;
					background: transparent;
					resize: none;
					font-family: $main-font;
					padding:0;
				}
			}

			&--group {
				.object {
					pointer-events: none;
				}
				&.is-editing {
					.object {
						pointer-events: auto;
					}
				}
			}

			&.is-selected {
				.object {
					&:after {
						content:'';
						position: absolute;
						top:0;
						bottom:0;
						left:0;
						right:0;
						border:2px solid rgba($blue, 0.5);
						pointer-events: none;
					}
				}
			}

			&.is-editing {
				overflow: visible;

				&.object--text {
					z-index: 999999;
				}
				// &:after {
				// 	content:'';
				// 	position: absolute;
				// 	top:0;
				// 	bottom:0;
				// 	left:0;
				// 	right:0;
				// 	border:2px solid $blue;
				// 	z-index: 2;
				// 	pointer-events: none;
				// }
			}
		}
	}

	.guides {
		position:absolute;
		top:0;
		left:0;
		z-index: 2;
	}

	.guide-lines {
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		z-index: 1;
		pointer-events: none;
		overflow: hidden;
		.gl {
			&--h1, &--h2 {
				position:absolute;
				left:0;
				right:0;
				height:1px;
				background: $guide-lines;
			}
			&--v1, &--v2 {
				position:absolute;
				top:0;
				bottom:0;
				width:1px;
				background: $guide-lines;
			}
		}
	}

	&.is-editing {
		.clicktrap {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background: rgba(#000,0.2);
			z-index: 20000;
		}
		.object.is-editing {
			z-index: 30000;
			background: rgba(#fff,0.6);
		}
	}
}

.mode--select {
	.artboard {
		.objects {
			.object {
				cursor:pointer;
			}
		}
	}
}
.mode--select:not(.is-resizing) {
	.artboard {
		.objects {
			.object:not(.is-selected) {
				&:hover {
					&:after {
						content:'';
						position: absolute;
						top:0;
						bottom:0;
						left:0;
						right:0;
						border:2px solid rgba($blue, 0.5);
						z-index: 2;
						pointer-events: none;
					}
				}
			}
		}
	}
}

.mode--text,
.mode--image,
.mode--list,
.mode--repeater,
.mode--shape,
.mode--group  {
	.artboard {
		&__inner {
			cursor: crosshair;
		}
	}
}
