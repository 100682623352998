// vars
$min-width: 1024px;

$main-font: 'Open Sans', sans-serif;
$header-font: 'Ubuntu', sans-serif;

$blue: #50CAF5;
$green: #28a745;
$pink: #BB2C78;

$guide-lines: #fe37a1;

$light-grey: #f7f7f7;
$mid-grey: #f4f4f4;
$grey: #EDEDED;
$dark-grey: #6c757d;

$text-dark: #4D4F5C;
$text-mid: #707070;
$text-grey: #919191;

$complete: #50CAF5;
$complete-light: #e3f1f8;
$error: #c80000;
$error-light: #f9e5e5;
$pending: #F7941E;
$success: #7aa266;
$success-light: #c0e9c1;
$warning: #FFCA83;
$warning-light: #feefdb;
$required: #F84610;


$border: #ccc;
$border-light: #e7e7e7;
$border-dark: #707070;
